import styles from "./assets/css/MainFooter.module.css";
import { Link } from "react-router-dom";
import logo from "./assets/img/tech_touch_logo.webp";
import kybLogo from "./assets/img/kyb.webp";
import xTrigLogo from "./assets/img/x_trig.webp";
import yoshimuraLogo from "./assets/img/yoshimura.webp";
import hinsonLogo from "./assets/img/hinson.webp";
import odiLogo from "./assets/img/odi.webp";
import thumbsUpIcon from "./assets/img/thumbs_up_icon.webp";
import peopleIcon from "./assets/img/people_icon.webp";
import worldIcon from "./assets/img/world_icon.webp";
import calendarIcon from "./assets/img/calendar_icon.webp";
import NumberTicker from "../../Components/NumberTicker/NumberTicker";
import TeamMembers from "../TeamMembers/TeamMembers";
import { useEffect, useRef, useState } from "react";

// Intersection Observer for footer section
const options = {
  root: null,
  rootMargin: "0px",
  threshold: 0.1,
};

const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      entry.target.classList.add(styles.visible);
      observer.unobserve(entry.target);
    }
  });
}, options);

const MainFooter = ({ pathname }) => {
  const [currentPath, setCurrentPath] = useState(pathname);
  const footerRef = useRef(null);

  useEffect(() => {
    setCurrentPath(pathname);
  }, [pathname]);

  useEffect(() => {
    if (footerRef.current) {
      observer.observe(footerRef.current);
    }
  }, [footerRef]);

  if (currentPath === "/brands" || currentPath === "/dealers") return;

  return (
    <>
      <section className={styles.footer} ref={footerRef}>
        <div className={`${styles.footerContainer} container`}>
          {currentPath === "/contact" ? (
            <>
              <h4 className={styles.ctaTitle}>
                Interested in becoming a technical touch dealer or distributor?
              </h4>
              <p className={styles.ctaText}>
                Do you work in the off road industry? Would you like to join our
                ever expanding dealer network, which enjoy exclusive discounts
                on our extensive range of products? If the answer is yes, click
                on the link below to apply for an account with us.
              </p>
              <a
                href="https://technicaltouch-shop.com/customer/account/create/"
                rel="noreferrer"
                target="_blank"
                className={styles.ctaLink}
              >
                Tap here to join our worldwide network
              </a>
            </>
          ) : (
            <>
              {currentPath === "/about" ? (
                <TeamMembers showTeamMembers={true} />
              ) : (
                ""
              )}
              <h4>
                A worldwide network of distributors &amp; authorized dealers
              </h4>
              <div className={styles.statGrid}>
                <div className={styles.stat}>
                  <img
                    src={thumbsUpIcon}
                    alt="thumbs up icon"
                    className={styles.icon}
                    width={55}
                    height={50}
                    loading="lazy"
                  />
                  <p className={styles.text}>Distributing</p>
                  <div className={styles.scrollerContainer}>
                    <div className={styles.character}>
                      <p>Premium</p>
                    </div>
                  </div>
                  <p className={styles.text}>Brands</p>
                </div>
                <div className={styles.stat}>
                  <img
                    src={peopleIcon}
                    alt="group of people icon"
                    className={styles.icon}
                    width={81}
                    height={50}
                    loading="lazy"
                  />
                  <p className={styles.text}>To Over</p>
                  <div className={styles.scrollerContainer}>
                    <div className={styles.character}>
                      <NumberTicker value={4000} />
                    </div>
                  </div>
                  <p className={styles.text}>Customers</p>
                </div>
                <div className={styles.stat}>
                  <img
                    src={worldIcon}
                    alt="wireframe of globe icon"
                    className={styles.icon}
                    width={50}
                    height={50}
                    loading="lazy"
                  />
                  <p className={styles.text}>In more than</p>
                  <div className={styles.scrollerContainer}>
                    <div className={styles.character}>
                      <NumberTicker value={25} />
                    </div>
                  </div>
                  <p className={styles.text}>Countries</p>
                </div>
                <div className={styles.stat}>
                  <img
                    src={calendarIcon}
                    alt="wireframe of calendar icon"
                    className={styles.icon}
                    width={57}
                    height={50}
                    loading="lazy"
                  />
                  <p className={styles.text}>For over</p>
                  <div className={styles.scrollerContainer}>
                    <div className={styles.character}>
                      <NumberTicker value={30} />
                    </div>
                  </div>
                  <p className={styles.text}>Years</p>
                </div>
              </div>
              <p className={styles.description}>
                To streamline the product distribution, we have set up a
                specialised network of Suspension Dealers and Distributors, all
                handpicked and selected so we can guarantee the highest possible
                quality for our end customers.
              </p>
              <Link to={"/dealers"} className={styles.buttonLink}>
                Discover your nearest
              </Link>
              <a className={styles.buttonLink} href="/">
                Join our worldwide network
              </a>
            </>
          )}
          <img
            className={styles.ttLogo}
            src={logo}
            alt="technical touch logo"
            height={129}
            width={300}
            loading="lazy"
          />
          <div className={styles.brandsContainer}>
            <img
              src={kybLogo}
              alt="kyb logo"
              width={154}
              height={24}
              loading="lazy"
            />
            <img
              src={xTrigLogo}
              alt="x trig logo"
              width={167}
              height={50}
              loading="lazy"
            />
            <img
              src={yoshimuraLogo}
              alt="yoshimura logo"
              width={129}
              height={80}
              loading="lazy"
            />
            <img
              src={hinsonLogo}
              alt="hinson logo"
              width={171}
              height={45}
              loading="lazy"
            />
            <img
              src={odiLogo}
              alt="odi logo"
              width={145}
              height={60}
              loading="lazy"
            />
          </div>
          <nav>
            <ul>
              <li>
                <Link to={"/about"}>About us</Link>
              </li>
              <li>
                <Link to={"/brands"}>Brands</Link>
              </li>
              <li>
                <Link to={"/dealers"}>Dealer Locator</Link>
              </li>
              <li>
                <Link to={"/contact"}>Contact</Link>
              </li>
            </ul>
          </nav>
          <a
            href="https://technicaltouch-shop.com/"
            target="_blank"
            rel="noreferrer"
            className={styles.buttonLink}
          >
            Click here to visit our trade only webshop
          </a>
        </div>
      </section>
      <section className={styles.noticePolicies}>
        <div className={`${styles.noticePoliciesContainer} container`}>
          <nav>
            <ul>
              <li>
                <a href="/terms">Terms &amp; Conditions</a>
              </li>
              <li>
                <a href="/privacy">Privacy Policy</a>
              </li>
            </ul>
          </nav>
          <p>Technical Touch BV, Ondernmersstraat 20, 3920 Lommel, Belgium</p>
          <p>&copy; 2023 Technical Touch. All rights reserved.</p>
        </div>
      </section>
    </>
  );
};
export default MainFooter;
