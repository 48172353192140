import styles from "./assets/css/Nav.module.css";
import { Link, NavLink } from "react-router-dom";

import logo from "./assets/img/tech_touch_logo.webp";
import { useEffect, useState } from "react";

const Nav = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const closeOnClick = () => {
    setMenuOpen(false);
  };

  useEffect(() => {
    document.title = "Technical Touch";
    setMenuOpen(false);
  }, []);

  useEffect(() => {
    document.body.style.overflow = menuOpen ? "hidden" : "";
  }, [menuOpen]);
  return (
    <>
      <nav className={styles.mobileNav}>
        <Link to={"/"} className={styles.logo}>
          <img
            src={logo}
            alt="technical touch logo"
            width={"102"}
            height={"44"}
            onClick={closeOnClick}
            loading="lazy"
          />
        </Link>
        <button
          className={`${styles.menuButton} ${menuOpen && styles.open}`}
          onClick={() => setMenuOpen((current) => !current)}
        >
          <div className={styles.menuBar} aria-label="menu icon"></div>
          <div className={styles.menuBar} aria-label="menu icon"></div>
          <div className={styles.menuBar} aria-label="menu icon"></div>
        </button>
        <div className={`${styles.menu} ${menuOpen && styles.open}`}>
          <ul>
            <li>
              <NavLink to={"/about"} onClick={closeOnClick}>
                About Us
              </NavLink>
            </li>
            <li>
              <NavLink to={"/brands"} onClick={closeOnClick}>
                Brands
              </NavLink>
            </li>
            <li>
              <NavLink to={"/dealers"} onClick={closeOnClick}>
                Dealer Locator
              </NavLink>
            </li>
            <li>
              <NavLink to={"/contact"} onClick={closeOnClick}>
                Contact
              </NavLink>
            </li>
          </ul>
          <Link to={"/"} className={styles.joinLink}>
            Join Our Worldwide Network
          </Link>
          <Link
            to={"https://technicaltouch-shop.com/"}
            target="_blank"
            referrerPolicy="no-referrer"
            className={styles.shopLink}
          >
            Trade Only Webshop
          </Link>
        </div>
      </nav>
      <nav className={styles.nav}>
        <div className={`${styles.navContainer} container`}>
          <Link to={"/"} className={styles.logo}>
            <img
              src={logo}
              alt="technical touch logo"
              width={"186"}
              height={"80"}
              loading="lazy"
            />
          </Link>
          <div className={styles.navLinks}>
            <ul>
              <li>
                <NavLink to={"/about"}>About Us</NavLink>
              </li>
              <li>
                <NavLink to={"/brands"}>Brands</NavLink>
              </li>
              <li>
                <NavLink to={"/dealers"}>Dealer Locator</NavLink>
              </li>
              <li>
                <NavLink to={"/contact"}>Contact</NavLink>
              </li>
            </ul>
          </div>
          <div className={styles.externalLinks}>
            <Link
              to={"https://technicaltouch-shop.com/customer/account/create/"}
              target="_blank"
              referrerPolicy="no-referrer"
              className={styles.joinLink}
            >
              Join Our Network
            </Link>
            <Link
              to={"https://technicaltouch-shop.com/"}
              target="_blank"
              referrerPolicy="no-referrer"
              className={styles.shopLink}
            >
              Trade Only Webshop
            </Link>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Nav;
