import styles from "./assets/css/TeamMembers.module.css";
import useFetch from "../../Hooks/useFetch";
import { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";

const TeamMembers = () => {
  const [departments, setDepartments] = useState([]);
  const { data: teamMembers } = useFetch("team_member/?per_page=100");
  const [activeDepartmentIndex, setActiveDepartmentIndex] = useState("");
  const [activeDepartmentName, setActiveDepartmentName] = useState("");
  const teamMembers1 = useRef();
  const teamMembers2 = useRef();
  const [fadeOut, setFadeOut] = useState(false);

  // React Hook that executes the provided function when dependencies change
  useEffect(() => {
    // Check if teamMembers is not defined or falsy, then return early
    if (!teamMembers) return;

    // Define a custom sort order for different departments
    const customSortOrder = {
      management: 0,
      sales: 1,
      "workshop technicians": 2,
      "mx gp technicians": 3,
      warehouse: 4,
      accounting: 5,
      "security (dogs)": 6,
    };

    // Extract unique department values from teamMembers, sort them based on customSortOrder
    // and remove duplicates using Set. Then, update the state with the sorted unique departments.
    setDepartments([
      ...new Set(
        teamMembers
          .map((member) => member.acf.department)
          .sort((a, b) => {
            // Get the custom sort order for each department, default to maximum safe integer if not found
            const orderA =
              customSortOrder[a.toLowerCase()] !== undefined
                ? customSortOrder[a.toLowerCase()]
                : Number.MAX_SAFE_INTEGER;

            const orderB =
              customSortOrder[b.toLowerCase()] !== undefined
                ? customSortOrder[b.toLowerCase()]
                : Number.MAX_SAFE_INTEGER;

            // Sort based on the custom order
            return orderA - orderB;
          })
      ),
    ]);
  }, [teamMembers]);

  useEffect(() => {
    if (departments.length <= 0) return;
    setActiveDepartmentIndex(0);
  }, [departments]);

  useEffect(() => {
    if (activeDepartmentIndex === null || departments.length === 0) return;
    setActiveDepartmentName(departments[activeDepartmentIndex]);
  }, [activeDepartmentIndex, departments]);

  return (
    <section className={styles.teamMembers}>
      <div className={`${styles.teamMembersContainer} container`}>
        <h4 className={styles.teamMembersTitle}>Discover Our Team</h4>
        <div className={styles.teamMembersSelectorMobile}>
          <select
            className={styles.teamMembersSelect}
            onChange={(e) => setActiveDepartmentIndex(+e.target.value)}
            value={activeDepartmentIndex}
          >
            {departments.length > 0 &&
              departments.map((department, index) => (
                <option key={index} value={index}>
                  {department}
                </option>
              ))}
          </select>
        </div>
        <div className={styles.teamProfilesMobile}>
          {teamMembers &&
            teamMembers
              .filter(
                (teamMember) =>
                  teamMember.acf.department ===
                  departments[activeDepartmentIndex]
              )
              .sort((a, b) => a.acf.order - b.acf.order)
              .map((teamMember, index) => (
                <div className={styles.profile} key={index}>
                  <div className={styles.profileImageContainer}>
                    <img
                      src={teamMember.acf.profile_image}
                      alt={`Portrait of ${teamMember.title.rendered} from technical touch`}
                      loading="lazy"
                    />
                  </div>
                  <p className={styles.profileName}>
                    {teamMember.title.rendered}
                  </p>
                  <p className={styles.jobTitle}>{teamMember.acf.job_title}</p>
                </div>
              ))}
        </div>
        <div className={styles.teamMembersSelector}>
          {departments.length > 0 &&
            departments.map((department, index) => (
              <button
                key={index}
                type="button"
                onClick={() => {
                  setFadeOut(true);

                  setTimeout(() => {
                    setFadeOut(false);
                    setActiveDepartmentIndex(index);
                  }, 200);
                }}
                className={`${styles.departmentButton} ${
                  activeDepartmentIndex === index ? styles.active : ""
                }`}
              >
                {department}
              </button>
            ))}
        </div>
        <CSSTransition
          in={
            !fadeOut &&
            activeDepartmentName === departments[activeDepartmentIndex]
          }
          nodeRef={teamMembers1}
          timeout={200}
          classNames={{
            appear: styles.currentDepartmentAppear,
            appearActive: styles.currentDepartmentActiveAppear,
            appearDone: styles.currentDepartmentDoneAppear,
            enter: styles.currentDepartmentEnter,
            enterActive: styles.currentDepartmentActiveEnter,
            enterDone: styles.currentDepartmentDoneEnter,
            exit: styles.currentDepartmentExit,
            exitActive: styles.currentDepartmentActiveExit,
            exitDone: styles.currentDepartmentDoneExit,
          }}
        >
          <div
            className={styles.teamMembersDisplayContainer}
            ref={teamMembers1}
          >
            {teamMembers &&
              departments.length > 0 &&
              teamMembers
                .filter(
                  (teamMember) =>
                    teamMember.acf.department ===
                    departments[activeDepartmentIndex]
                )
                .sort((a, b) => a.acf.order - b.acf.order)
                .map((teamMember, index) => (
                  <div key={index}>
                    <div className={styles.profileImageContainer}>
                      <img
                        src={teamMember.acf.profile_image}
                        alt={`Portrait of ${teamMember.title.rendered} from technical touch`}
                        loading="lazy"
                      />
                    </div>
                    <p className={styles.profileName}>
                      {teamMember.title.rendered}
                    </p>
                    <p className={styles.jobTitle}>
                      {teamMember.acf.job_title}
                    </p>
                  </div>
                ))}
          </div>
        </CSSTransition>
        <CSSTransition
          in={
            !fadeOut &&
            activeDepartmentName === departments[activeDepartmentIndex]
          }
          nodeRef={teamMembers2}
          timeout={200}
          classNames={{
            appear: styles.currentDepartmentAppear,
            appearActive: styles.currentDepartmentActiveAppear,
            appearDone: styles.currentDepartmentDoneAppear,
            enter: styles.currentDepartmentEnter,
            enterActive: styles.currentDepartmentActiveEnter,
            enterDone: styles.currentDepartmentDoneEnter,
            exit: styles.currentDepartmentExit,
            exitActive: styles.currentDepartmentActiveExit,
            exitDone: styles.currentDepartmentDoneExit,
          }}
        >
          <div
            className={styles.teamMembersDisplayContainer2}
            ref={teamMembers2}
            onClick={() => setActiveDepartmentIndex(activeDepartmentIndex + 1)}
          >
            {teamMembers &&
              departments.length > 0 &&
              teamMembers
                .filter(
                  (teamMember) =>
                    teamMember.acf.department ===
                    departments[activeDepartmentIndex + 1]
                )
                .sort((a, b) => a.acf.order - b.acf.order)
                .map((teamMember, index) => (
                  <div key={index}>
                    <div className={styles.profileImageContainer}>
                      <img
                        src={teamMember.acf.profile_image}
                        alt={`Portrait of ${teamMember.title.rendered} from technical touch`}
                        loading="lazy"
                      />
                    </div>
                  </div>
                ))}
          </div>
        </CSSTransition>
      </div>
    </section>
  );
};
export default TeamMembers;
