import * as React from "react";
import * as ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga4";

// Default Styles
import "./index.css";

import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Google Analytics
ReactGA.initialize("G-YG7GJ9N0KH");

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
