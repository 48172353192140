import { useEffect, useState } from "react";

/**
 * Custom hook for fetching data from a specified API endpoint with optional query parameters.
 *
 * @param {string} apiEndpoint - The API endpoint to fetch data from.
 * @param {string} [customRoute="wp-json/wp/v2"] - The custom route to append to the API URI.
 * @returns {Object} An object containing the fetched data, loading state, and error state.
 */
const useFetch = (apiEndpoint, customRoute = "wp-json/wp/v2") => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const abortController = new AbortController();
    setLoading(true);
    setData(null);
    setError(null);

    const apiUrl = `${process.env.REACT_APP_API_URI}/${customRoute}/${apiEndpoint}`;

    fetch(apiUrl, {
      signal: abortController.signal,
    })
      .then((res) => res.json())
      .then((data) => {
        setLoading(false);
        setData(data);
      })
      .catch((err) => {
        if (err.name === "AbortError") return;
        console.error(err);
        setLoading(false);
        setError(err);
      });

    return () => abortController.abort();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiEndpoint]);

  return { data, loading, error };
};

export default useFetch;
