import { Outlet, useLocation } from "react-router-dom";
import Nav from "../../Components/Nav/Nav";
import MainFooter from "../../Components/MainFooter/MainFooter";
import { useEffect } from "react";
import CookieConsent from "react-cookie-consent";
import ReactGA from "react-ga4";

const RootLayout = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "instant",
    });
    ReactGA.send({ hitType: "pageview", page: pathname });
  }, [pathname]);

  return (
    <>
      <Nav />
      <Outlet />
      <MainFooter pathname={pathname} />
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        cookieName="cookieConsent"
        style={{ background: "#ed2024" }}
        buttonStyle={{
          backgroundColor: "#333",
          color: "var(--primary-color)",
          fontSize: "16px",
          padding: "10px 20px",
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </>
  );
};
export default RootLayout;
