import { createElement, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import styles from "./assets/css/NumberTicker.module.css";

const NumberTicker = ({ value }) => {
  // Ref to the ticker element
  const tickerRef = useRef();
  const { pathname } = useLocation();

  // State to store digit elements
  const [digitElements, setDigitElements] = useState([]);

  useEffect(() => {
    // Check if the tickerRef is available
    if (!tickerRef.current) return;

    // Convert the numeric value to an array of characters
    const valueAsArray = value.toString().split("");

    // Array to store created digit elements
    const createdElements = [];

    // Loop through each character in the value
    valueAsArray.forEach((character, index) => {
      // Array of digits
      const digits = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

      // Array to store digit track digits
      const digitTrackDigits = [];
      for (let i = 0; i <= index + 1; i++) {
        digitTrackDigits.push(digits.join(" "));
      }

      // Create digit track element
      const digitTrackElement = createElement(
        "span",
        {
          className: styles.digitTrack,
          "data-offset": `${41 * (index + 1 + character)}`,
        },
        digitTrackDigits.join(" ")
      );

      // Create digit element
      const digitElement = createElement(
        "p",
        { key: index, className: styles.digit },
        digitTrackElement
      );

      // Add digit element to the array
      createdElements.push(digitElement);

      // Update state with the array of digit elements
      setDigitElements(createdElements);
    });

    // Intersection Observer options
    const options = {
      root: null,
      rootMargin: "-35px",
      threshold: 1,
    };

    // Intersection Observer callback
    const callback = (entries, observer) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          for (const child of entry.target.children) {
            const tickerTrack = child.children[0];
            tickerTrack.style.opacity = 1;
            tickerTrack.style.translate = `0 -${tickerTrack.dataset.offset}px`;
          }
          observer.unobserve(entry.target);
        }
      });
    };

    // Create Intersection Observer with the callback and options
    const observer = new IntersectionObserver(callback, options);

    // Observe the tickerRef element
    observer.observe(tickerRef.current);
  }, [value, pathname]);

  return (
    // Render the ticker element with digit elements
    <div className={styles.ticker} ref={tickerRef} key={pathname}>
      {digitElements}
    </div>
  );
};

export default NumberTicker;
