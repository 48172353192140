import { Suspense, lazy } from "react";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";

// Layouts
import RootLayout from "./Layouts/RootLayout/RootLayout";

// Pages
const Home = lazy(() => import("./Pages/Home/Home"));
const Brands = lazy(() => import("./Pages/Brands/Brands"));
const Contact = lazy(() => import("./Pages/Contact/Contact"));
const NotFound = lazy(() => import("./Pages/NotFound/NotFound"));
const About = lazy(() => import("./Pages/About/About"));
const DealerLocator = lazy(() =>
  import("./Components/DealerLocator/DealerLocator")
);
const PrivacyPolicy = lazy(() => import("./Pages/PrivacyPolicy/PrivacyPolicy"));
const Terms = lazy(() => import("./Pages/Terms/Terms"));

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<RootLayout />}>
      <Route path="/" element={<Home />} />
      <Route path="about" element={<About />} />
      <Route path="brands" element={<Brands />} />
      <Route path="dealers" element={<DealerLocator />} />
      <Route path="contact" element={<Contact />} />
      <Route path="privacy" element={<PrivacyPolicy />} />
      <Route path="terms" element={<Terms />} />
      <Route path="*" element={<NotFound />} />
    </Route>
  )
);

const App = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <RouterProvider router={router} />
    </Suspense>
  );
};
export default App;
